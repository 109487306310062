import React, { useState, useEffect } from 'react'
import { Link } from 'gatsby'
import Layout from '../containers/Layout'

// styles
const pageStyles = {
  color: '#232129',
  padding: '96px',
  fontFamily: '-apple-system, Roboto, sans-serif, serif'
}

// markup
const NotFoundPage = () => {
  const [isMount, setMount] = useState(false)
  useEffect(() => {
    setMount(true)
  }, [])
  return (
    <Layout>
      <main style={pageStyles}>
        {isMount && (
          <>
            <title>No emails found</title>
            <div className='container text-center'>
              <h1>Sorry, we can't find that page.</h1>
              <Link
                to='/'
                className='!text-red-400 btn btn-outline hover:!text-white'
              >
                Search the Email Database
              </Link>
            </div>
          </>
        )}
      </main>
    </Layout>
  )
}

export default NotFoundPage
